import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AppLogoMin from '../../assets/AppLogo.svg';
import FlagUs from '../../assets/FlagUs.svg';
import FlagIndia from '../../assets/FlagIndia.svg';
import { useWindowSize } from '../../utils/useWindowSize';
import ArrowLeft from '../../assets/ArrowLeft.svg';
import CentralContentPageLayout from '../../Layout/CentralContentPageLayout';
import CentralGridLayout from '../../Layout/CentralGridLayout';
import InputField from '../../atoms/InputField';
import DownArrow from '../../assets/DownArrow.svg';
import UpArrow from '../../assets/UpArrow.svg';
import {
  ButtonVariant,
  ButtonType,
  CustomButton,
} from '../../atoms/CustomButton';
import {
  routeUrls,
  ISD_CODE,
  errorMsg,
  startPageFlow,
} from '../../utils/constants';
import {
  useSendOTP,
  useVerifyOTP,
  useVerifyOTPSignUp,
  useGetMyProfile,
  useGetActiveDater,
} from '../../services/mutations';
import {
  localStorageKeys,
  localStorageUserType,
  useLocalStorage,
} from '../../utils/localStorageItems';
import Loader from '../../molecule/Loader';
import { scaleByRatio } from '../../utils/helper';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import SvgIcon from '@mui/material/SvgIcon';
import Box from '@mui/material/Box';

const arrISDCodes = [
  {
    value: '+1',
    flag: (
      <img
        src={FlagUs}
        alt="US"
        style={{
          height: `${scaleByRatio(24)}px`,
          width: `${scaleByRatio(24)}px`,
        }}
      />
    ),
  },
  {
    value: '+91',
    flag: (
      <img
        src={FlagIndia}
        alt="IND"
        style={{
          height: `${scaleByRatio(24)}px`,
          width: `${scaleByRatio(24)}px`,
        }}
      />
    ),
  },
];

const LoginPage = () => {
  const location = useLocation();
  const nav = useNavigate();
  const sendOTP = useSendOTP();
  const verifyOTP = useVerifyOTP();
  const verifyOTPSignUp = useVerifyOTPSignUp();
  const localStorageItem = useLocalStorage();
  const getMyProfile = useGetMyProfile();
  const [height, width] = useWindowSize();
  const [getwidth, setWidth] = useState(0);
  const [mobileNumber, setMobileNumber] = useState('');
  const [error, setError] = useState('');
  const [otpError, setOtpError] = useState('');
  const [activeStep, setActiveStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const getActiveDater = useGetActiveDater();
  console.log('getActiveDater: ', getActiveDater);
  const [otp1, setOtp1] = useState('');
  const [otp2, setOtp2] = useState('');
  const [otp3, setOtp3] = useState('');
  const [otp4, setOtp4] = useState('');
  const [otp5, setOtp5] = useState('');
  const [otp6, setOtp6] = useState('');
  const [ISDCode, setISDCode] = useState('+1');
  const [openFlag, setOpenFlag] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWidth(window?.innerWidth);
    }
  }, [width]);

  const onMobileNumberChange = (e) => {
    console.log('onMobileNumberChange: ', e.target.value);
    let mobileNumber = e.target.value;
    if (mobileNumber.length <= 10) {
      setMobileNumber(e.target.value);
    }
  };
  const validatePhoneNumber = (phNum) => {
    console.log('validatePhoneNumber: ', phNum);
    let phRegex = /^[0-9]{10}$/;
    if (phRegex.test(phNum)) {
      setError('');
      return true;
    } else {
      setError('Please provide a valid phone number.');
      return false;
    }
  };
  const onNextClicked = (evt) => {
    console.log('....onNextClicked');
    if (validatePhoneNumber(mobileNumber)) {
      console.log('Call api for mobile num:', ISDCode + mobileNumber);
      let data = {};
      data.phone_number = ISDCode + mobileNumber;
      setLoading(true);
      sendOTP.mutate(data, {
        onSuccess: (response) => {
          console.log('sendOTP SUCCESS: ', response);
          setActiveStep(2);
          setTimeout(() => {
            document.getElementById(`OTP1`).focus();
          }, 200);
          setLoading(false);
        },
        onError: (err) => {
          console.log('sendOTP ERROR: ', err);
          setError(errorMsg.genericPhoneNumberError);
          setLoading(false);
        },
      });
    }
  };
  const onOtp1Change = (e) => {
    let otp = e.target.value;
    console.log('Tab index:', e.target.tabIndex);
    if (otp.length <= 1) {
      if (validateOTPNumber(e.target.value)) {
        setOtp1(e.target.value);
        if (otp.length === 1) {
          document.getElementById(`OTP2`).focus();
        }
      } else {
        setOtp1('');
      }
    }
  };
  const onOtp2Change = (e) => {
    let otp = e.target.value;
    console.log('Tab index:', e.target.tabIndex);
    if (otp.length <= 1) {
      if (validateOTPNumber(e.target.value)) {
        setOtp2(e.target.value);
        if (otp.length === 1) {
          document.getElementById(`OTP3`).focus();
        }
      } else {
        setOtp2('');
      }
    }
  };
  const onOtp3Change = (e) => {
    let otp = e.target.value;
    console.log('Tab index:', e.target.tabIndex);
    if (otp.length <= 1) {
      if (validateOTPNumber(e.target.value)) {
        setOtp3(e.target.value);
        if (otp.length === 1) {
          document.getElementById(`OTP4`).focus();
        }
      } else {
        setOtp3('');
      }
    }
  };
  const onOtp4Change = (e) => {
    let otp = e.target.value;
    console.log('Tab index:', e.target.tabIndex);
    if (otp.length <= 1) {
      if (validateOTPNumber(e.target.value)) {
        setOtp4(e.target.value);
        if (otp.length === 1) {
          document.getElementById(`OTP5`).focus();
        }
      } else {
        setOtp4('');
      }
    }
  };
  const onOtp5Change = (e) => {
    let otp = e.target.value;
    console.log('Tab index:', e.target.tabIndex);
    if (otp.length <= 1) {
      if (validateOTPNumber(e.target.value)) {
        setOtp5(e.target.value);
        if (otp.length === 1) {
          document.getElementById(`OTP6`).focus();
        }
      } else {
        setOtp5('');
      }
    }
  };
  const onOtp6Change = (e) => {
    let otp = e.target.value;
    console.log('Tab index:', e.target.tabIndex);

    if (otp.length <= 1) {
      if (validateOTPNumber(e.target.value)) {
        setOtp6(e.target.value);
      } else {
        setOtp6('');
      }
    }
  };
  const validateOTPNumber = (value) => {
    let otpRegex = /^[0-9]{1}$/;
    if (otpRegex.test(value)) {
      return true;
    } else {
      return false;
    }
  };
  const resetOTP = () => {
    setOtp1('');
    setOtp2('');
    setOtp3('');
    setOtp4('');
    setOtp5('');
    setOtp6('');
  };
  const onOTPNextClicked = () => {
    let finalOTP =
      otp1 + '' + otp2 + '' + otp3 + '' + otp4 + '' + otp5 + '' + otp6;
    let phRegex = /^[0-9]{6}$/;
    if (phRegex.test(finalOTP)) {
      setOtpError('');
      console.log('OTP API CALL: ', finalOTP);
      let data = {
        phone_number: ISDCode + mobileNumber,
        otp: finalOTP,
        is_dater:
          localStorageItem.getLocalStorageItem(localStorageKeys.userType) ===
          localStorageUserType.dater,
      };
      setLoading(true);
      if (location.state.currentFlow === startPageFlow.signup) {
        verifyOTPSignUp.mutate(data, {
          onSuccess: (response) => {
            setLoading(false);
            console.log('verifyOTPSignUp SUCCESS: ', response);
            let token = response?.data?.payload?.token;
            localStorageItem.setLocalStorageItem(
              localStorageKeys.accessToken,
              token,
            );
            if (response?.data?.payload?.new_user) {
              nav(routeUrls.myProfile, {
                state: { currentFlow: startPageFlow.signup },
              });
            } else if (!response?.data?.payload?.new_user) {
              //check for any pending questions
              fetchProfileData();
              // fetchActiveDaterOfFriend();
            }
          },
          onError: (error) => {
            setLoading(false);
            setOtpError(
              // error?.response?.data?.error || errorMsg.genericOTPErro,
              `If you are already someone's friend on meadow , you can sign in as a dater to create a dating profile.`,
            );
          },
        });
      } else {
        verifyOTP.mutate(data, {
          onSuccess: (response) => {
            console.log('verifyOTP SUCCESS: ', response);
            let token = response?.data?.payload?.token;
            localStorageItem.setLocalStorageItem(
              localStorageKeys.accessToken,
              token,
            );
            localStorage?.setItem('token', response?.data?.payload?.token);
            localStorage?.setItem(
              'profileImage',
              response?.data?.payload?.user?.profile_pic,
            );
            if (response?.data?.payload?.new_user) {
              nav(routeUrls.myProfile, {
                state: { currentFlow: startPageFlow.signup },
              });
            } else if (!response?.data?.payload?.new_user) {
              //check for any pending questions
              fetchProfileData();
            }
            setLoading(false);
          },
          onError: (err) => {
            console.log('verifyOTP ERROR: ', err);
            resetOTP();
            setOtpError(err?.response?.data?.error || errorMsg.genericOTPError);
            setLoading(false);
          },
        });
      }
    } else {
      setOtpError('Please provide a valid OTP.');
    }
  };

  const fetchActiveDaterOfFriend = () => {
    console.log('fetchActiveDaterOfFriend.....##');
    setLoading(true);
    getActiveDater.mutate(
      {},
      {
        onSuccess: (response) => {
          setLoading(false);
          console.log(
            'getActiveDater onSuccess##: ',
            response,
            response?.data?.payload?.user?.profile_pic,
          );
          localStorage.setItem(
            'activeDaterImage',
            response?.data?.payload?.user?.profile_pic,
          );
          localStorageItem.setLocalStorageItem(
            localStorageKeys.isLoggedIn,
            true,
          );
          nav(routeUrls.home);
        },
        onError: (error) => {
          setLoading(false);
          console.log('getActiveDater onError: ', error);
        },
      },
    );
  };
  const fetchProfileData = () => {
    let data = {};
    getMyProfile.mutate(data, {
      onSuccess: (response) => {
        console.log('getMyProfile onSuccess: ', response);
        let _pendingFields = response?.data?.payload?.pending_fields;
        let _payload = response?.data?.payload;

        localStorageItem.setLocalStorageItem(
          localStorageKeys.profileId,
          _payload?.id,
        );

        if (
          localStorageItem.getLocalStorageItem(localStorageKeys.userType) ===
          localStorageUserType.dater
        ) {
          //if(!_pendingFields){
          if (location.state.currentFlow === startPageFlow.login) {
            //login success
            localStorageItem.setLocalStorageItem(
              localStorageKeys.isLoggedIn,
              true,
            );
            nav(routeUrls.home);
          } else {
            nav(routeUrls.myProfile, {
              state: { currentFlow: startPageFlow.signup },
            });
          }
        } else if (
          localStorageItem.getLocalStorageItem(localStorageKeys.userType) ===
          localStorageUserType.friend
        ) {
          fetchActiveDaterOfFriend();
        }
      },
      onError: (err) => {
        console.log('getMyProfile onError: ', err);
        setLoading(false);
      },
    });
  };
  const handleISDChange = (evt) => {
    console.log(evt.target.value);
    setISDCode(evt.target.value);
  };

  const handleFlagOpen = () => {
    setOpenFlag(true);
  };

  const handleFlagClose = () => {
    setOpenFlag(false);
  };

  const onBackClicked = () => {
    if (activeStep === 2) {
      setActiveStep(1);
      setOtpError('');
      setOtp1('');
      setOtp2('');
      setOtp3('');
      setOtp4('');
      setOtp5('');
      setOtp6('');
    }
  };

  return (
    <>
      <Loader open={loading} />
      <CentralContentPageLayout>
        {activeStep === 1 && (
          <CentralGridLayout>
            {/* <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-end">
              <Grid item className={styles.logoContainer}>
                <img
                  src={AppLogoMin}
                  alt={'App icon'}
                  className={styles.logo}
                  style={{ height: `${scaleByRatio(126)}px` }}
                />
              </Grid>
            </Grid> */}
            
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="flex-end"
              marginTop={11}
              marginBottom={7}
              >
                
              <Grid item >
                <Typography
                  textAlign="left"
                  className={styles.descriptionMessage}
                  style={{
                    lineHeight: `${scaleByRatio(22)}px`,
                    letterSpacing: '0.12em',
                    fontSize: `${scaleByRatio(18)}px`,
                    // lineHeight: `${scaleByRatio(40)}px`,
                    width: '230px',
                  }}>
                  Let's start with your phone number
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="flex-end"
              >
              {/* <Grid item xs={12}>
                            <Typography textAlign='left' className={styles.description}
                            style={{
                                fontSize:`${scaleByRatio(24)}px`,
                                lineHeight: `${scaleByRatio(40)}px`
                            }}
                            >
                            Mobile No
                            </Typography>
                        </Grid> */}
              <Grid
                item
                //  xs={getwidth > 600 ? 3.5 : 4.5}
                // style={{ paddingRight: '25px' }}
                className={styles.centerOnSmallScreen}>
                {/* <InputField
                            name='Code'
                            id={'Code'}
                            value={'+1'}
                            placeholder={'Mobile Number'}
                            handleChange={onMobileNumberChange}
                            type={'text'}
                            class_name={styles.inputMobileNumber}
                            disabled={true}
                            prefix={<img src={FlagUs} alt='US' style={{height:`${scaleByRatio(24)}px`, width: `${scaleByRatio(24)}px`}}/>}
                            style={{
                                fontSize:`${scaleByRatio(20)}px`,
                                height: `${scaleByRatio(40)}px`
                            }}
                            /> */}
                <FormControl variant="standard" fullWidth>
                  <Select
                    labelId={'ISD'}
                    name={'ISDCODE'}
                    id={'ISDCODE'}
                    value={ISDCode}
                    onChange={handleISDChange}
                    variant="filled"
                    class_name={styles.inputMobileNumber}
                    onClose={handleFlagClose}
                    onOpen={handleFlagOpen}
                    IconComponent={() => (
                      openFlag? 
                     <img
                      src={UpArrow}
                      alt="Up Arrow"
                      style={{
                        height: `${scaleByRatio(15)}px`,
                        width: `${scaleByRatio(15)}px`,
                        marginLeft: `${scaleByRatio(-40)}px`,
                      }}
                      />:
                      <img
                        src={DownArrow}
                        alt="Down Arrow"
                        style={{
                          height: `${scaleByRatio(15)}px`,
                          width: `${scaleByRatio(15)}px`,
                          marginLeft: `${scaleByRatio(-40)}px`,
                        }}
                        />
                    )}
                    sx={{
                      '.MuiSelect-filled': {
                        fontFamily :'Gill Sans Nova',
                        height: `${scaleByRatio(30)}px !important`,
                        width: `${scaleByRatio(100)}px !important`,
                        paddingX: `${scaleByRatio(30)}px`,
                        paddingY: `${scaleByRatio(10)}px`,
                        fontSize: `${scaleByRatio(10)}px`,
                        // borderBottom: `1px solid #C4C4C4`,
                        paddingTop: `${scaleByRatio(2)}px`,
                        paddingBottom: `${scaleByRatio(2)}px`,
                        paddingLeft: '0px',
                        paddingRight: '25px !important',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#FFFFFF',
                        borderRadius: `${scaleByRatio(50)}px`,
                      },
                    }}>
                    {arrISDCodes.map((menu) => {
                      return (
                        <MenuItem
                          value={menu.value}
                          style={{
                            fontSize: `${scaleByRatio(10)}px`,
                            // height: `${scaleByRatio(40)}px`
                            paddingBottom: `${scaleByRatio(5)}px`,
                          }}
                          class_name={styles.inputMobileNumber}>
                          <Typography
                            style={{
                              float: 'left',
                              marginRight: `${scaleByRatio(5)}px`,
                              lineHeight: 'unset',
                              display: 'flex',
                            }}>
                            {menu.flag}
                          </Typography>
                          <Typography
                            style={{
                              float: 'right',
                              fontSize: `${scaleByRatio(14)}px`,
                              paddingLeft: `${scaleByRatio(5)}px`,
                              color: '#9D9FA2',
                              lineHeight: 'unset',
                              fontFamily: 'Gill Sans Nova',
                            }}>
                            {menu.value}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item className={styles.mobileInputDiv}>
                {/* <TextField label="Mobile Number" variant="filled" type="number" className={styles.inputMobileNumber}/> */}
                <InputField
                  name="MobileNum"
                  id={'MobileNum'}
                  value={mobileNumber}
                  placeholder={'Mobile Number'}
                  handleChange={onMobileNumberChange}
                  type={'number'}
                  minHeightPX={'40px'}
                  class_name={styles.inputMobileNumber}
                  style={{
                    fontSize: `${scaleByRatio(20)}px`,
                    height: `${scaleByRatio(30)}px`,
                  }}
                />
              </Grid>
              {error && error?.length > 0 && (
                <Grid item xs={12}>
                  <Typography
                    textAlign="left"
                    className={styles.error}
                    style={{
                      fontSize: `${scaleByRatio(12)}px`,
                      // lineHeight: `${scaleByRatio(40)}px`,
                    }}>
                    {error}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="flex-end"
              marginTop={7}
              >
  <Grid item>
  <Typography
    textAlign="left"
    className={styles.descriptionMessage}
    style={{
      lineHeight: `${scaleByRatio(22)}px`,
      letterSpacing: '0.12em',
      fontSize: `${scaleByRatio(24)}px`,
      whiteSpace: 'pre-line', // Ensures that \n creates a line break
    }}>
    {`We’ll text you\na code to confirm that you are you :)`}
  </Typography>
</Grid>

            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="end"
              alignItems="flex-end"
              className={styles.nextBtn}>
              <Grid item>
                <CustomButton
                  text={'Next'}
                  variant={ButtonVariant.contained}
                  type={ButtonType.nextButton}
                  customStyle={`yes`}
                  onClick={onNextClicked}
                />
              </Grid>
            </Grid>
          </CentralGridLayout>
        )}
        {activeStep === 2 && (
          <CentralGridLayout top='28%'>
            
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-end">
              <Grid item className={styles.logoContainer}>
                <img
                  src={AppLogoMin}
                  alt={'App icon'}
                  // className={styles.logo}
                  style={{ height: `${scaleByRatio(90)}px`, marginBottom:'90px' }}
                />
              </Grid>
            </Grid> 

            <Grid container item direction="row" justifyContent="left" alignItems="center" xs={12}>
              {/* <Typography
                textAlign="left"
                className={styles.back}
                onClick={onBackClicked}
                style={{
                  fontSize: `${scaleByRatio(16)}px`,
                  paddingRight: `${scaleByRatio(10)}px`,
                  paddingBottom: `${scaleByRatio(5)}px`,
                  float: `left`,
                }}> */}
                 

                <img
                  src={ArrowLeft}
                  alt={'Back icon'}
                  className={styles.logoBack}
                  onClick={onBackClicked}
                  width={scaleByRatio(12)}
                  height={scaleByRatio(12)}
                />
              {/* </Typography> */}
              <Typography
                textAlign="left"
                className={styles.back}
                onClick={onBackClicked}
                style={{
                  fontSize: `${scaleByRatio(12)}px`,
                  paddingLeft: `${scaleByRatio(10)}px`,
                  paddingBottom: `${scaleByRatio(5)}px`,
                  paddingTop: `${scaleByRatio(3)}px`,
                }}>
                Back
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="flex-end">
              <Grid item xs={12}>
                <Typography
                  textAlign="left"
                  className={styles.description}
                  style={{
                    fontSize: `${scaleByRatio(24)}px`,
                    lineHeight: `${scaleByRatio(40)}px`,
                    marginBottom: `${scaleByRatio(20)}px`,
                  }}>
                  Enter Your Verification Code
                </Typography>
              </Grid>

              <Grid item xs={2} style={{ paddingRight: '5px', height: '56px' }}>
                {/* <TextField label="Mobile Number" variant="filled" type="number" className={styles.inputMobileNumber}/> */}
                <InputField
                  name="OTP1"
                  id={'OTP1'}
                  value={otp1}
                  placeholder={''}
                  handleChange={onOtp1Change}
                  type={'number'}
                  class_name={styles.inputMobileNumber}
                  tabIndex="1"
                />
              </Grid>
              <Grid item xs={2} style={{ paddingRight: '5px', height: '56px' }}>
                {/* <TextField label="Mobile Number" variant="filled" type="number" className={styles.inputMobileNumber}/> */}
                <InputField
                  name="OTP2"
                  id={'OTP2'}
                  value={otp2}
                  placeholder={''}
                  handleChange={onOtp2Change}
                  type={'number'}
                  class_name={styles.inputMobileNumber}
                  tabIndex="2"
                />
              </Grid>
              <Grid item xs={2} style={{ paddingRight: '5px', height: '56px' }}>
                {/* <TextField label="Mobile Number" variant="filled" type="number" className={styles.inputMobileNumber}/> */}
                <InputField
                  name="OTP3"
                  id={'OTP3'}
                  value={otp3}
                  placeholder={''}
                  handleChange={onOtp3Change}
                  type={'number'}
                  class_name={styles.inputMobileNumber}
                  tabIndex="3"
                />
              </Grid>
              <Grid item xs={2} style={{ paddingRight: '5px', height: '56px' }}>
                {/* <TextField label="Mobile Number" variant="filled" type="number" className={styles.inputMobileNumber}/> */}
                <InputField
                  name="OTP4"
                  id={'OTP4'}
                  value={otp4}
                  placeholder={''}
                  handleChange={onOtp4Change}
                  type={'number'}
                  class_name={styles.inputMobileNumber}
                  tabIndex="4"
                />
              </Grid>
              <Grid item xs={2} style={{ paddingRight: '5px', height: '56px' }}>
                {/* <TextField label="Mobile Number" variant="filled" type="number" className={styles.inputMobileNumber}/> */}
                <InputField
                  name="OTP5"
                  id={'OTP5'}
                  value={otp5}
                  placeholder={''}
                  handleChange={onOtp5Change}
                  type={'number'}
                  class_name={styles.inputMobileNumber}
                  tabIndex="5"
                />
              </Grid>
              <Grid item xs={2} style={{ paddingRight: '5px', height: '56px' }}>
                {/* <TextField label="Mobile Number" variant="filled" type="number" className={styles.inputMobileNumber}/> */}
                <InputField
                  name="OTP6"
                  id={'OTP6'}
                  value={otp6}
                  placeholder={''}
                  handleChange={onOtp6Change}
                  type={'number'}
                  class_name={styles.inputMobileNumber}
                  tabIndex="6"
                />
              </Grid>
              {otpError && otpError?.length > 0 && (
                <Grid item xs={12}>
                  <Typography textAlign="left" className={styles.error}>
                    {otpError}
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="end"
              alignItems="flex-end"
              className={styles.nextBtn}>
              <Grid item>
                <CustomButton
                  text={'Next'}
                  customStyle={'yes'}
                  variant={ButtonVariant.contained}
                  type={ButtonType.nextButton}
                  onClick={onOTPNextClicked}
                />
              </Grid>
            </Grid>
          </CentralGridLayout>
        )}
      </CentralContentPageLayout>
    </>
  );
};

export default LoginPage;
